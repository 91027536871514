import React, {useEffect, useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../assets/css/Cta.css';

function Cta() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });
  }, []);
  const scrollTo = (el) => {
    el.preventDefault();
    const href = el.currentTarget.getAttribute('href');
    const offsetTop = document.querySelector(href)?.offsetTop - document.querySelector('#header').offsetHeight;

    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    });

    if (!isNavCollapsed) setIsNavCollapsed(true);
  };
  return (
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">
        <div className="row">
          <div className="col-lg-9 text-center text-lg-start">
            <h3>Call To Action</h3>
            <p> Ready to take your business to the next level? Partner with <span className='kb-style'>KB</span><span className='solutions-style'>Solutions</span> today and let us transform your ideas into innovative, impactful solutions. Whether you’re looking to streamline operations, enhance your digital presence, or elevate your brand, our team of experts is here to help. Contact us now to discuss how we can collaborate to achieve your goals and drive your success. At <span className='kb-style'>KB</span><span className='solutions-style'>Solutions</span>, your vision is our mission—let’s develop your dreams into reality together.</p>
          </div>
          <div className="col-lg-3 cta-btn-container text-center">
            <a className="cta-btn align-middle" href="#contact" onClick={scrollTo}>Call To Action</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
