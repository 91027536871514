import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {CheckListItem} from "./Lists/CheckListItem";
import '../assets/css/About.css';

function About() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
  }, []);

  const aboutContent = [
    { title: 'Driving Innovation', content: 'Utilize cutting-edge technologies to deliver groundbreaking solutions that advance our clients' },
    { title: 'Fostering Long-Term Partnerships', content: 'Build enduring relationships with trust, transparency, and superior service' },
    { title: 'Delivering Excellence', content: 'Consistently provide high-quality, reliable software that enhances performance and growth' },
    { title: 'Supporting Client Success', content: 'We tailor solutions to fit your needs, ensuring our work drives your overall success and goals' },
  ];

  return (
    <section id='about' >
      <div className='container' data-aos="fade-up">
        <div className='section-title'>
          <h2>About Us</h2>
        </div>
        <div className='row content'>
          <div className='col-lg-6'>
            <p>
              Welcome to <span className='kb-style'>KB</span><span className='solutions-style'>Solutions</span>, where
              innovation and expertise converge to create transformative digital experiences. As a dynamic software
              house, we specialize in crafting bespoke software solutions that drive significant business success. Our
              team of visionaries, technologists, and strategists is united by a passion for cutting-edge technology and
              a commitment to excellence. At <span className='kb-style'>KB</span><span
                className='solutions-style'>Solutions</span>, we don’t just deliver projects; we forge partnerships,
              working closely with you to ensure every solution aligns with your business goals. Our approach is rooted
              in understanding your unique challenges, allowing us to create tailor-made solutions that propel your
              business forward. Whether you aim to revolutionize your industry or streamline operations, <span
                className='kb-style'>KB</span><span className='solutions-style'>Solutions</span> is your partner in
              turning vision into reality, ensuring every project is a step toward greater success
            </p>
          </div>
          <div className='col-lg-6 pt-4 pt-lg-0'>
            <p>
              Our mission at <span className='kb-style'>KB</span><span className='solutions-style'>Solutions</span> is to empower businesses by transforming their ideas into cutting-edge technology solutions. We are committed to:
            </p>
            <ul className='no-bullet'>
              {aboutContent.map((item, index) => (
                  <CheckListItem key={index}>{item}</CheckListItem>
              ))}
            </ul>
          </div>
        </div>
        <div className='section-title'>
          <h3>Let's Develop Your Dreams</h3>
          {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
          <h3></h3>
          <div className='col-lg-12 pt-4 pt-lg-0'>
            At <span className='kb-style'>KB</span><span className='solutions-style'>Solutions</span>, we believe that
            every great idea deserves the chance to succeed. Whether you’re a startup looking to make your mark or an
            established company seeking innovation, we’re here to turn your vision into reality. Let’s collaborate to
            create the technology solutions that will drive your business forward.
          </div>
        </div>

      </div>
    </section>
  );
}

export default About;
