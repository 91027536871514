import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../assets/css/Services.css';
import {CardListItem} from "./Lists/CardListItem";

function Services() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
  }, []);

  const services = [
    {
      iconClass: "bx bxs-dashboard",
      title: "Enterprise Management Solutions",
      description: "We design and implement sophisticated management applications that streamline business processes, enhance operational efficiency, and deliver actionable insights. Our solutions are crafted to optimize resource management, performance tracking, and strategic decision-making.",
      delay: "100"
    },
    {
      iconClass: "bx bx-devices",
      title: "Mobile Application Development",
      description: "Our mobile application development services deliver cutting-edge, user-centric solutions for both iOS and Android platforms. We focus on creating seamless, high-performance mobile experiences that align with your business objectives and engage your target audience.",
      delay: "200"
    },
    {
      iconClass: "bx bxl-sketch",
      title: "Brand Design and Identity",
      description: "We provide expert design and branding services that define and enhance your brand’s identity. From developing distinctive visual elements to creating cohesive brand strategies, we ensure your brand makes a strong impact and communicates effectively in the marketplace.",
      delay: "300"
    },
    {
      iconClass: "bx bx-slideshow",
      title: "Strategic Marketing Solutions",
      description: "Our marketing services are designed to elevate your brand’s visibility and drive growth. We employ data-driven strategies and innovative techniques to optimize your marketing efforts, engage your audience, and achieve measurable results through various digital and traditional channels.",
      delay: "400"
    }
  ];

  return (
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>At <span className='kb-style'>KB</span><span className='solutions-style'>Solutions</span>, we offer a comprehensive suite of specialized services meticulously designed to address the essential aspects of your business needs. Our expertise spans several critical domains, ensuring that we deliver tailored solutions to support every facet of your operations. From advanced management solutions that streamline and optimize your processes, to cutting-edge mobile technology that enhances user engagement, we are dedicated to providing top-tier services. Our commitment to design excellence ensures that your brand’s visual identity stands out and resonates with your audience, while our strategic marketing solutions employ innovative, data-driven strategies to amplify your market presence. By integrating these services, <span className='kb-style'>KB</span><span className='solutions-style'>Solutions</span> provides a holistic approach to drive your business growth and long-term success.</p>
        </div>
        <div className="row">
          {services.map((service, index) => (
            <CardListItem key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
