import React from "react";


export const CollapsedListItem = ({ children }) => (
    <li>
        {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
        <a data-bs-toggle="collapse" className={children.isOpened === 1 ? "collapse" : "collapse collapsed"}
           data-bs-target={ "#accordion-list-" + children.id }
           aria-expanded={children.isOpened === 1 ? "true" : "false"}
        >
            {children.icon &&
                <i className={children.icon}></i>
            }
            {!children.icon &&
                <span>0{children.id}</span>
            }
            <span className="collapsed-list-title">{children.title}</span> <i className="bx bx-chevron-down icon-show"></i><i
                className="bx bx-chevron-up icon-close"></i></a>
        <div id={ "accordion-list-" + children.id } className={children.isOpened === 1 ? "collapse show" : "collapse"}
             data-bs-parent=".faq-list">
            <p>{children.content}</p>
        </div>
    </li>
);