import React from "react";


export const CardListItem = ({ iconClass, title, description, delay }) => (
    <div className={`col-xl-3 col-md-6 d-flex align-items-stretch mt-4 ${delay ? `mt-xl-0` : ''}`} data-aos="zoom-in"
         data-aos-delay={delay}>
        <div className="icon-box">
            <div className="icon"><i className={iconClass}></i></div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <h4><a href="">{title}</a></h4>
            <p>{description}</p>
        </div>
    </div>
);