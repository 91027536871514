import 'aos/dist/aos.css';
import Nav from './Components/Nav';
import About from './Components/About';
import Client from './Components/Client';
import WhyUs from './Components/WhyUs';
import Banner from './Components/Banner';
import Cta from './Components/Cta';
import Services from './Components/Services';
import Faq from './Components/Faq';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
    <Nav />
      <Banner/>
     <Client />
     <About />
     <WhyUs />
     <Services />
     <Cta />
     <Faq />
    <Contact />
     <Footer/>
    </div>
  );
}

export default App;
