import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../assets/css/Whyus.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import {CollapsedListItem} from "./Lists/CollapsedListItem";


function WhyUs() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
            mirror: false
        });
    }, []);
    const whyUsPoints = [
        { id: 1, title: 'Multidisciplinary Expertise', content: 'Our extensive experience across various industries equips us with unique insights and innovative perspectives, ensuring comprehensive solutions tailored to your specific challenges.', isOpened: 1 },
        { id: 2, title: 'Client-Centric Focus', content: 'We prioritize your needs and goals, engaging closely with you to deliver solutions that not only meet but exceed your expectations, fostering a strong and collaborative partnership.', isOpened: 0 },
        { id: 3, title: 'Uncompromising Quality', content: 'Our dedication to quality is reflected in our adherence to industry best practices and meticulous testing processes, guaranteeing reliable, high-performance solutions that stand the test of time.', isOpened: 0 },
        { id: 4, title: 'Agile Adaptability', content: 'We employ agile methodologies to remain flexible and responsive, adeptly managing evolving requirements and ensuring timely, efficient project delivery.', isOpened: 0 },
    ];
    return (
        <div>
            <section id="why-us" className="why-us section-bg">
                <div className='section-title'>
                    <h2>Why <span className='kb-style'>KB</span>-<span className='solutions-style'>Solutions</span>?</h2>
                </div>
                <div className="container-fluid" data-aos="fade-up">
                    <div className="row">
                        <div
                            className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                            <div className="content">
                                <h3><strong>Developing</strong> Your <strong>Dreams</strong> into <strong>Achievements</strong></h3>
                                <p>
                                    At <span className='kb-style'>KB</span><span className='solutions-style'>Solutions</span>, we distinguish ourselves through our unwavering commitment to excellence and our client-focused approach. Our goal is to provide unparalleled value and drive success for our clients by delivering top-tier technology solutions tailored to their needs.
                                </p>
                            </div>
                            <div className="accordion-list">
                                <ul>
                                    {whyUsPoints.map((item, index) => (
                                        <CollapsedListItem key={index}>{item}</CollapsedListItem>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 why-bg-img align-items-stretch order-1 order-lg-2 img"
                             data-aos="zoom-in" data-aos-delay="150"></div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default WhyUs;
