import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../assets/css/Faq.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import {CollapsedListItem} from "./Lists/CollapsedListItem";


function Faq() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
  }, []);

  const faqPoints = [
    { id: 21, title: 'What types of businesses do you work with?', content: 'We work with a wide range of businesses, from startups and small businesses to large enterprises across various industries. Our solutions are tailored to meet the unique needs of each client, regardless of size or sector.', isOpened: 0, icon: "bx bx-help-circle icon-help" },
    { id: 22, title: 'What is your process for developing custom software solutions?', content: 'Our process begins with a detailed consultation to understand your business goals and requirements. We then move through the stages of planning, design, development, testing, and deployment, with regular feedback loops to ensure the final product meets your expectations.', isOpened: 0, icon: "bx bx-help-circle icon-help"},
    { id: 23, title: 'How long does it typically take to develop a mobile application?', content: 'The timeline for mobile app development depends on the complexity of the project and specific requirements. On average, development can take anywhere from a few weeks to several months. We work closely with you to establish realistic timelines and ensure timely delivery.', isOpened: 0, icon: "bx bx-help-circle icon-help" },
    { id: 24, title: 'Can you help us rebrand or redesign our existing website or app?', content: 'Yes, we offer comprehensive design and branding services that include rebranding and redesigning existing websites and apps. We’ll work with you to create a fresh, modern look that aligns with your brand identity and business goals.', isOpened: 0, icon: "bx bx-help-circle icon-help" },
    { id: 25, title: 'Do you offer ongoing support and maintenance after the project is completed?', content: 'Absolutely. We provide ongoing support and maintenance services to ensure your software, applications, and systems continue to operate smoothly and efficiently. Our team is available to assist with updates, troubleshooting, and enhancements as needed.', isOpened: 0, icon: "bx bx-help-circle icon-help" },
    { id: 26, title: 'What sets KB Solutions apart from other software development companies?', content: 'At KB Solutions, we pride ourselves on our client-centric approach, our commitment to quality, and our ability to deliver innovative, tailored solutions that drive business success. Our multidisciplinary team brings a wealth of experience across various industries, allowing us to approach each project with a unique perspective.', isOpened: 0, icon: "bx bx-help-circle icon-help" },
  ];

  return (
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Frequently Asked Questions</h2>
            <p>
              At KB Solutions, we understand that choosing the right partner for your business needs is a significant
              decision. To help you better understand our services and approach, we’ve compiled a list of frequently
              asked questions. Whether you’re curious about our development process, the industries we serve, or the
              ongoing support we provide, our FAQs are designed to give you clear and concise answers. Explore the
              section below to learn more about how we can assist in turning your vision into reality.
            </p>
          </div>

          <div className="faq-list">
            <ul>
              {faqPoints.map((item, index) => (
                  <CollapsedListItem key={index + 10}>{item}</CollapsedListItem>
              ))}
            </ul>
          </div>
          <div className="col-lg-5 why-bg-img align-items-stretch order-1 order-lg-2 img"
               data-aos="zoom-in" data-aos-delay="150"></div>
        </div>
      </section>
  );
}

export default Faq;
